<template>
  <div class="not-found">
    <div>Not Found 404</div>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  methods: {
    updateValue(value) {
      this.emit('input', value)
    }
  }
}
</script>
<style>
.not-found {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
</style>
